/* eslint-env browser */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { setCopyToClipboard } from '@aarhus-university/au-designsystem-delphinus/source/js/components/nav';

const AUProfileWidgetComponent = React.memo(({
  lang,
  name,
  auid,
  studentNumber,
  settings,
  changeLanguageUrl,
  logoutUri,
  profileLabels,
}) => {
  const [ctcSet, setCtcSet] = useState(false);
  const renderSettings = settings.map((s) => <a key={s.url} className="sub-nav__item" href={s.url}>{s.text}</a>);

  useEffect(() => {
    if (auid > 0 && !ctcSet) {
      setCopyToClipboard(lang);
      setCtcSet(true);
    }
  });

  return (
    <div className="sub-nav__content theme--normal" aria-labelledby="profile-menu-toggle">
      <div className="sub-nav__user">
        <h2 className="sub-nav__user-name">{name}</h2>
        <button
          type="button"
          className="copy-to-clipboard"
          title={profileLabels[lang].copyAuid}
          aria-label={`${profileLabels[lang].auId}: AU${auid}`}
        >
          {`${profileLabels[lang].auId}: `}
          <span className="copy-to-clipboard__this">{`AU${auid}`}</span>
        </button>
        {
          studentNumber && (
            <button
              type="button"
              className="copy-to-clipboard"
              title={profileLabels[lang].copyStudentNumber}
              aria-label={`${profileLabels[lang].studentNumber}: ${studentNumber}`}
            >
              {`${profileLabels[lang].studentNumber}: `}
              <span className="copy-to-clipboard__this">{studentNumber}</span>
            </button>
          )
        }
      </div>
      <hr />
      {renderSettings}
      <hr />
      {
        changeLanguageUrl && (
          <>
            <a className="sub-nav__item sub-nav__item--icon" href={changeLanguageUrl} data-icon="">{profileLabels[lang].changeLanguage}</a>
            <hr />
          </>
        )
      }
      <a
        className="sub-nav__item sub-nav__item--icon"
        data-icon=""
        href={logoutUri}
      >
        {profileLabels[lang].logout}
      </a>
    </div>
  );
});

AUProfileWidgetComponent.defaultProps = {
  studentNumber: null,
  changeLanguageUrl: null,
};

AUProfileWidgetComponent.propTypes = {
  lang: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  auid: PropTypes.number.isRequired,
  studentNumber: PropTypes.string,
  settings: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
  profileLabels: PropTypes.shape({}).isRequired,
  changeLanguageUrl: PropTypes.string,
  logoutUri: PropTypes.string.isRequired,
};

AUProfileWidgetComponent.displayName = 'AUProfileWidgetComponent';
export default AUProfileWidgetComponent;
