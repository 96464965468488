/* eslint-env browser */
/* NO IMPORTS! */

const { serviceScopeProfile } = window; // Sættes pr. webapp

const sortAlphaObj = (a, b) => {
  if (typeof a.name !== 'undefined' && typeof b.name !== 'undefined') {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase(), 'da', { ignorePunctuation: true });
  }
  return 0;
};

const isElementInViewport = (element) => {
  if (element === null) {
    return false;
  }

  const rect = element.getBoundingClientRect();

  return rect.bottom > 0
    && rect.right > 0
    && rect.left < (window.innerWidth || document.documentElement.clientWidth)
    && rect.top < (window.innerHeight || document.documentElement.clientHeight);
};

const isElementPartlyInViewport = (element) => {
  let top = element.offsetTop;
  let left = element.offsetLeft;
  const width = element.offsetWidth;
  const height = element.offsetHeight;

  let offsetElement = element;
  while (offsetElement.offsetParent) {
    offsetElement = element.offsetParent;
    top += element.offsetTop;
    left += element.offsetLeft;
  }

  return (
    top < (window.pageYOffset + window.innerHeight)
    && left < (window.pageXOffset + window.innerWidth)
    && (top + height) > window.pageYOffset
    && (left + width) > window.pageXOffset
  );
};

const setCookie = (cookieName, value, maxAge = null, exdays = null, path = ';path=/;domain=au.dk') => {
  let cookieValue = encodeURI(value);
  if (exdays != null) {
    const exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    cookieValue = `${cookieValue}; expires=${exdate.toUTCString()}`;
  } else if (maxAge != null) {
    cookieValue = `${cookieValue}; max-age=${maxAge}`;
  }

  document.cookie = `${cookieName}=${cookieValue}${path}`;
};

const getCookie = (cookieName) => {
  let cookieValue = document.cookie;
  let cookieStart = cookieValue.indexOf(` ${cookieName}=`);
  if (cookieStart === -1) {
    cookieStart = cookieValue.indexOf(`${cookieName}=`);
  }
  if (cookieStart === -1) {
    cookieValue = null;
  } else {
    cookieStart = cookieValue.indexOf('=', cookieStart) + 1;
    let cookieEnd = cookieValue.indexOf(';', cookieStart);
    if (cookieEnd === -1) {
      cookieEnd = cookieValue.length;
    }
    cookieValue = decodeURI(cookieValue.substring(cookieStart, cookieEnd));
  }
  return cookieValue;
};

const addToSessionStorage = (key, data) => {
  if (window.sessionStorage) {
    sessionStorage.setItem(key, JSON.stringify(data));
  }

  return data;
};

const getFromSessionStorage = (key) => {
  if (window.sessionStorage) {
    const data = sessionStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
  }

  return null;
};

const addToLocalStorage = (key, data) => {
  if (window.localStorage) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  return data;
};

const getFromLocalStorage = (key) => {
  if (window.localStorage) {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
  }

  return null;
};

const setContextInSession = (key, data, clearPrevious = true) => {
  if (clearPrevious) {
    Object.entries(window.sessionStorage).forEach((entry) => {
      const entryKey = entry[0];
      if (entryKey.includes('user-context')) {
        window.sessionStorage.removeItem(entryKey);
      }
    });
  }
  window.sessionStorage.setItem(`user-context-${key}`, data);
};

const getContextFromSession = (key = '', any = false) => {
  if (any) {
    let foundContext = null;
    Object.entries(window.sessionStorage).forEach((entry) => {
      const entryKey = entry[0];
      if (entryKey.includes('user-context')) {
        foundContext = window.sessionStorage.getItem(entryKey);
      }
    });
    return foundContext;
  }

  return window.sessionStorage.getItem(`user-context-${key}`);
};

// Credits: http://blog.vishalon.net/index.php/javascript-getting-and-setting-caret-position-in-textarea/
const setCaretPosition = (element, pos) => {
  // Modern browsers
  if (element && element.setSelectionRange) {
    element.focus();
    element.setSelectionRange(pos, pos);
  }
};

const splitPhoneNumber = (countryCodes, phoneNumber) => {
  const countryCodeFound = (code) => countryCodes.filter(
    (x) => x.code === parseInt(code, 10),
  ).length > 0;

  const findPrefix = () => {
    if (countryCodes.length > 0) {
      const skip = phoneNumber.length > 0 ? '' : '+'; // Hackish...
      let count = 1;
      let prefix = countryCodes.find((x) => x.important).code;
      while (count < phoneNumber.length) {
        const split = phoneNumber.substring(skip.length, skip.length + count);
        if (countryCodeFound(split)) {
          prefix = split;
        }
        count += 1;
      }
      return skip + prefix;
    }
    return '';
  };

  const prefix = findPrefix();
  return {
    prefix,
    number: phoneNumber.replace(prefix, ''),
  };
};

const prettyPrintPhone = (countryCodes, phone) => {
  if (phone) {
    const { prefix, number } = splitPhoneNumber(countryCodes, phone);
    if (prefix) {
      return `${prefix} ${number.match(/\d{1,2}/g).join(' ')}`;
    }
    return number.match(/\d{1,2}/g).join(' ');
  }
  return '';
};

const getAccessToken = (auAuth, serviceScope, callback) => {
  auAuth.getAccessToken([serviceScope]).then((accessToken) => {
    callback(accessToken.accessToken);
  });
};

const parseError = (errorArray) => errorArray.map((e) => e.errorMessage).join(', ');

const scrollTo = (x = 0, y = 0) => window.scrollTo(x, y);

export {
  sortAlphaObj,
  isElementInViewport,
  isElementPartlyInViewport,
  setCookie,
  getCookie,
  addToLocalStorage,
  getFromLocalStorage,
  addToSessionStorage,
  getFromSessionStorage,
  setCaretPosition,
  getAccessToken,
  serviceScopeProfile,
  splitPhoneNumber,
  prettyPrintPhone,
  parseError,
  scrollTo,
  setContextInSession,
  getContextFromSession,
};
