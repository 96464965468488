/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-env browser */
/* eslint max-len: 0 */
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import AUCollapsibleComponent from '../AUCollapsibleComponent';
import AUProfileLoginComponent from '../profile/AUProfileLoginComponent';
import AUProfileAvatar2016Component from '../profile/AUProfileAvatar2016Component';
import { getCookie, setCookie } from '../../lib/helpers';

const parseBurgerLinks = (elements) => {
  const links = [];
  elements.forEach((e) => {
    const link = e.querySelector('a');
    links.push({
      url: link.getAttribute('href'),
      title: link.innerText,
      className: e.getAttribute('class'),
    });
  });
  return links;
};

const parseSystemLinks = (elements) => {
  const systems = [];
  elements.forEach((e) => {
    const div = e.querySelector('div');
    const header = div.querySelector('h3').innerText;
    const text = div.querySelector('p').innerText;
    const actions = div.querySelectorAll('ul > li > a');
    const system = {
      header,
      text,
      actions: [],
    };
    actions.forEach((a) => {
      system.actions.push({
        url: a.getAttribute('href'),
        title: a.innerText,
      });
    });
    systems.push(system);
  });
  return systems;
};

class StaffTopComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: 0,
      burgerLinks: [],
      systemLinks: [],
      loggedIn: false,
      showHelp: false,
    };
  }

  componentDidMount() {
    const { promiseHandler, user, onLoad } = this.props;
    document.getElementById('fade').addEventListener('click', () => {
      this.setState({
        active: 0,
      });
    });

    promiseHandler((data) => {
      const container = document.createElement('div');
      container.innerHTML = data;
      const burgerLinks = container.querySelectorAll('ul.burger-links > li');
      const systemLinks = container.querySelectorAll('ul.systems > li');
      this.setState({
        burgerLinks: parseBurgerLinks(burgerLinks),
        systemLinks: parseSystemLinks(systemLinks),
      });
    });

    const visible = document.querySelector('body').classList.contains('login');

    if (visible) {
      fetch(`${window.profileApiUri}/v1/person/isauthenticated`, {
        method: 'GET',
        credentials: 'include',
      })
        .then((response) => response.json())
        .then((json) => {
          this.setState({
            loggedIn: json.isAuthenticated,
          }, () => {
            onLoad({ ...user, ...{ auId: json.auId } });
          });
        }).catch((err) => {
          console.log(err);
        });
    }
  }

  componentDidUpdate() {
    const { active } = this.state;
    const fade = document.getElementById('fade');
    if (active > 0) {
      fade.style.display = 'block';
    } else {
      fade.style.display = 'none';
    }
    fade.style.opacity = 0;
    if (active === 2 && typeof window.findWidgetInit !== 'undefined') {
      window.findWidgetInit('.universe-utility .find-container');
    }
    if (active === 3) {
      AU.alphabox.init();
    }
  }

  render() {
    const {
      lang,
      user,
      onLoad,
      universeLabels,
      profileLabels,
    } = this.props;
    const {
      active,
      burgerLinks,
      systemLinks,
      loggedIn,
      showHelp,
    } = this.state;

    const helpCookie = getCookie('mystaff-help');
    const visible = document.querySelector('body').classList.contains('login');
    const help = !helpCookie && !loggedIn && document.querySelector('body').classList.contains('help');

    const renderBurgerLinks = burgerLinks.map(
      (u) => (
        <li key={u.title} className={u.className}>
          <a href={u.url}>
            {u.title}
          </a>
        </li>
      ),
    );

    const renderSystemLinks = systemLinks.map(
      (s) => (
        <li key={s.header}>
          <AUCollapsibleComponent
            collapsed
            header={s.header}
            level={3}
          >
            <div>
              <p>
                {s.text}
              </p>
              <ul className="resetlist">
                {s.actions.map(
                  (a) => (
                    <li key={a.url}>
                      <a href={a.url}>{a.title}</a>
                    </li>
                  ),
                )}
              </ul>
            </div>
          </AUCollapsibleComponent>
        </li>
      ),
    );

    return [
      <div key="header" className="large-12 medium-12 small-12 columns">
        <div>
          <h2 className="universe-home" dangerouslySetInnerHTML={{ __html: universeLabels[lang].staffTitleLink }} />
          <div className={active === 4 ? 'avatar active' : 'avatar'}>
            {
              visible && (
                <>
                  <AUProfileLoginComponent
                    text={profileLabels[lang].myProfile}
                    loggedIn={loggedIn}
                    loginUri={`${window.profileApiUri}/auth/login?redirectUri=${window.location.href}`}
                  >
                    <AUProfileAvatar2016Component
                      lang={lang}
                      user={user}
                      settings={[
                        {
                          url: `${window.profileApiUri}/${lang}/profile`,
                          text: profileLabels[lang].headerContainer,
                        },                      
                        {
                          url: `${window.profileApiUri}/${lang}/password`,
                          text: profileLabels[lang].headerPassword,
                        },
                        {
                          url: `${window.profileApiUri}/${lang}/quick-edit`,
                          text: profileLabels[lang].headerPureProfile,
                        },                          
                        {
                          url: 'https://mithr.au.dk/',
                          text: profileLabels[lang].headerVacationAbsence,
                        },
                      ]}
                      profileLabels={profileLabels}
                      toggled={active === 4}
                      onToggle={() => {
                        this.setState((prevState) => ({
                          active: prevState.active === 4 ? 0 : 4,
                        }));
                      }}
                      onLoad={onLoad}
                      logoutUri={`${window.profileApiUri}/auth/logout?redirectUri=${window.location.href}`}
                    />
                  </AUProfileLoginComponent>
                  {
                    help && (
                      <>
                        <button
                          type="button"
                          className="btn-help"
                          aria-label="Hjælp / help"
                          onClick={() => {
                            this.setState({
                              showHelp: true,
                            });
                          }}
                        >
                          
                        </button>
                        {
                          showHelp && (
                            ReactDOM.createPortal(
                              <div className="help-modal">
                                <h2>{profileLabels[lang].helpModalHeader}</h2>
                                <p>
                                  {profileLabels[lang].helpModalText}
                                </p>
                                <button
                                  type="button"
                                  className="button bg-staff"
                                  onClick={() => {
                                    setCookie('mystaff-help', true, null, 365, ';path=/;domain=medarbejdere.au.dk');
                                    this.setState({
                                      showHelp: false,
                                    });
                                  }}
                                >
                                  {profileLabels[lang].helpModalBtn}
                                </button>
                              </div>,
                              document.querySelector('.main'),
                              'modal',
                            )
                          )
                        }
                      </>
                    )
                  }
                </>
              )
            }
          </div>
          {
            showHelp && (
              <div
                key="overlay"
                className="help-modal-overlay"
                role="button"
                onClick={() => {
                  this.setState({
                    showHelp: false,
                  });
                }}
              />
            )
          }
          <ul className="resetlist universe-icons">
            <li className="au" dangerouslySetInnerHTML={{ __html: universeLabels[lang].mainPageLink }} />
            <li className={active === 1 ? 'active' : null}>
              <button
                type="button"
                className="btn-system"
                onClick={() => {
                  this.setState((prevState) => ({
                    active: prevState.active === 1 ? 0 : 1,
                  }));
                }}
              >
                System
              </button>
            </li>
            <li className={active === 2 ? 'active' : null}>
              <button
                type="button"
                className="btn-find"
                onClick={() => {
                  this.setState((prevState) => ({
                    active: prevState.active === 2 ? 0 : 2,
                  }));
                }}
              >
                Find
              </button>
            </li>
            <li className={active === 3 ? 'active' : null}>
              <button
                type="button"
                className="btn-menu"
                onClick={() => {
                  this.setState((prevState) => ({
                    active: prevState.active === 3 ? 0 : 3,
                  }));
                }}
              >
                Menu
              </button>
            </li>
          </ul>
        </div>
      </div>,
      <div key={1} className={`universe-utility${active === 1 ? ' active' : ''}`}>
        <div className="universe-utility-links">
          <ul className="resetlist">
            {renderSystemLinks}
          </ul>
        </div>
      </div>,
      <div key={2} className={`universe-utility${active === 2 ? ' active' : ''}`}>
        <div className="find-container au-map" />
      </div>,
      <div key={3} className={`universe-utility${active === 3 ? ' active' : ''}`}>
        <div className="au_alphabox" id="au_alphabox_staff_utility" />
        <div className="universe-utility-links">
          <ul className="resetlist">
            {renderBurgerLinks}
          </ul>
        </div>
      </div>,
    ];
  }
}

/*
StaffTopComponent.defaultProps = {
  onLogin: () => { },
};
*/

StaffTopComponent.propTypes = {
  lang: PropTypes.string.isRequired,
  user: PropTypes.shape({
  }).isRequired,
  promiseHandler: PropTypes.func.isRequired,
  onLoad: PropTypes.func.isRequired,
  universeLabels: PropTypes.shape({}).isRequired,
  profileLabels: PropTypes.shape({}).isRequired,
};

StaffTopComponent.displayName = 'StaffTopComponent';
export default StaffTopComponent;
