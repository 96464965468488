/* eslint-env browser */
import React from 'react';
import PropTypes from 'prop-types';

const AUProfileLoginComponent = ({
  loggedIn,
  loginUri,
  children,
  text,
}) => {
  if (!loggedIn) {
    return (
      <a
        className="nav__item nav__item--icon nav__item--icon--right nav__item--icon--always-show-label"
        data-icon=""
        href={loginUri}
      >
        {text}
      </a>
    );
  }

  return children;
};

AUProfileLoginComponent.defaultProps = {
  loggedIn: false,
  loginUri: '',
  lang: 'da',
};

AUProfileLoginComponent.propTypes = {
  loggedIn: PropTypes.bool,
  loginUri: PropTypes.string,
  children: PropTypes.element.isRequired,
  lang: PropTypes.string,
  text: PropTypes.string.isRequired,
};

AUProfileLoginComponent.displayName = 'AUProfileLoginComponent';
export default AUProfileLoginComponent;
