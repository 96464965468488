/* eslint-env browser */
import React, { useState } from 'react';
import axios from 'axios';
import { universeLabels, profileLabels } from '../../lib/i18n';
import StaffTopComponent from './StaffTopComponent';
import StudentTopComponent from './StudentTopComponent';

const UniverseContainerComponent = () => {
  const [user, setUser] = useState({
    chosenFirstNames: '',
    chosenLastName: '',
    preferredLanguage: '',
    auId: 0,
  });

  const { auAuth } = window;
  const lang = document.querySelector('html').getAttribute('lang');
  const body = document.querySelector('body');
  const staffClass = 'staff';
  const studentClass = 'student';
  const { universeGlobalContentPath } = window;
  let universe = <div />;
  if (body.classList.contains(staffClass)) {
    universe = (
      <StaffTopComponent
        lang={lang}
        user={user}
        promiseHandler={(callback) => {
          axios.get(universeGlobalContentPath).then((promise) => {
            callback(promise.data);
          });
        }}
        onLoad={(context) => {
          setUser(context);
        }}
        auAuth={auAuth}
        universeLabels={universeLabels}
        profileLabels={profileLabels}
      />
    );
  }
  if (body.classList.contains(studentClass)) {
    universe = (
      <StudentTopComponent
        lang={lang}
        user={user}
        promiseHandler={(callback) => {
          axios.get(universeGlobalContentPath).then((promise) => {
            callback(promise.data);
          });
        }}
        universeLabels={universeLabels}
        profileLabels={profileLabels}
      />
    );
  }

  return universe;
};

UniverseContainerComponent.displayName = 'UniverseContainerComponent';
export default UniverseContainerComponent;
