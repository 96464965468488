const flowboxLabels = {
  da: {
    select: 'Vælg',
    back: 'Tilbage',
    building: 'Bygning',
    office: 'Kontor',
  },
  en: {
    select: 'Select',
    back: 'Back',
    building: 'Building',
    office: 'Office',
  },
};

const newsLabels = {
  da: {
    news: {
      listLoadMore: 'Indlæs flere',
      eventDate: 'Dato',
      eventTime: 'Tid',
      eventWhere: 'Sted',
      eventRegistration: 'Tilmelding',
      weekdays: ['søndag', 'mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'lørdag'],
      weekdaysShort: ['søn', 'man', 'tir', 'ons', 'tor', 'fre', 'lør'],
      months: ['januar', 'februar', 'marts', 'april', 'maj', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'december'],
      monthsShort: ['jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
      readMore: 'Læs mere',
      rssAdd: 'Klik på RSS-ikonet for at abonnere på listen i din RSS-læser.',
      rssCopy: 'Kopier linket til RSS-feedet fra tekstfeltet herunder.',
    },
    share: {
      facebook: 'Del på Facebook',
      twitter: 'Del på Twitter',
      linkedin: 'Del på LinkedIn',
      mail: 'Send til en ven',
      mailLabelLong: 'Send via e-mailklienten på din computer',
      mailLabelShort: 'E-mail',
      gmailLabelLong: 'Send via Gmail',
      gmailLabelShort: 'Gmail',
      yahooLabelLong: 'Send via Yahoo Mail',
      yahooLabelShort: 'Yahoo Mail',
      outlookLabelLong: 'Send via outlook.com',
      outlookLabelShort: 'Outlook.com',
    },
  },
  en: {
    news: {
      listLoadMore: 'Load more',
      eventDate: 'Date',
      eventTime: 'Time',
      eventWhere: 'Location',
      eventRegistration: 'Registration',
      weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      readMore: 'Read more',
      rssAdd: 'Click the RSS icon to subscribe to the list in your RSS reader.',
      rssCopy: 'Copy the link to the RSS feed from the textfield below.',
    },
    share: {
      facebook: 'Facebook share',
      twitter: 'Twitter share',
      linkedin: 'LinkedIn share',
      mail: 'Send to a friend',
      mailLabelLong: 'Send via the email client on your computer',
      mailLabelShort: 'E-mail',
      gmailLabelLong: 'Send via Gmail',
      gmailLabelShort: 'Gmail',
      yahooLabelLong: 'Send via Yahoo Mail',
      yahooLabelShort: 'Yahoo Mail',
      outlookLabelLong: 'Send via outlook.com',
      outlookLabelShort: 'Outlook.com',
    },
  },
};

const universeLabels = {
  da: {
    staffTitleLink: '<a href="//medarbejdere.au.dk"><strong>medarbejdere</strong>.au.dk</a>',
    studentTitleLink: '<a href="//studerende.au.dk"><strong>studerende</strong>.au.dk</a>',
    mainPageLink: '<a href="//www.au.dk">au.dk</a>',
    iconSystems: 'Systemer',
    iconPin: 'Personer og bygninger',
    iconBurger: 'Menu',
    mitstudieText: 'mitstudie',
    mitstudieUrl: 'https://mitstudie.au.dk',
  },
  en: {
    staffTitleLink: '<a href="//staff.au.dk"><strong>staff</strong>.au.dk</a>',
    studentTitleLink: '<a href="//students.au.dk"><strong>students</strong>.au.dk</a>',
    mainPageLink: '<a href="//international.au.dk">au.dk</a>',
    iconSystems: 'Systems',
    iconPin: 'Persons and buildings',
    iconBurger: 'Menu',
    mitstudieText: 'mystudies.au.dk',
    mitstudieUrl: 'https://mystudies.au.dk/',
  },
};

const profileLabels = {
  da: {
    login: 'Log ind',
    logout: 'Log ud',
    edit: 'Ret',
    auId: 'AU-id',
    studentNumber: 'Studienummer',
    changeLanguage: 'Change language',
    headerContainer: 'Profilindstillinger',
    headerName: 'Profilnavn',
    headerMail: 'Mailadresser',
    headerMobile: 'Privat mobilnummer',
    headerLanguage: 'Sprog i systemer',
    headerNextOfKin: 'Pårørende',
    otherMail: 'Se øvrige AU-mailadresser',
    languageIn: 'Du har valgt dansk som standardsprog',
    legendName: 'Vælg dit profilnavn til universitetets systemer',
    legendNameHint: 'Vær opmærksom på, at ændringer ikke slår igennem i alle systemer.',
    formNameHint: 'Dit navn fra CPR-registeret',
    formNameDisplayName: 'Skriv et andet navn',
    firstName: 'Fornavn',
    firstNameHint: 'Undlad mellemnavne, hvis du kun vil vises med ét fornavn',
    lastName: 'Efternavn',
    workMail: 'AU-mailadresse',
    workMailHint: 'Ændringer i din medarbejder-mailadresse skal afspejle dit navn',
    workMailScreenreader: 'Din medarbejder-mailadresse er ###MAIL###. Du kan kun rette den del, der kommer før @.',
    studentMail: 'Studie-mailadresse',
    studentMailScreenreader: 'Din studie-mailadresse er ###MAIL###. Du kan kun rette den del, der kommer før @.',
    privateMail: 'Privat mailadresse',
    privateMailHint: 'Vi sender en mail til din private mailadresse, hvis du glemmer dit brugernavn eller adgangskode til universitetets systemer.',
    countryCode: 'Landekode',
    number: 'Nummer',
    languageLegend: 'Vælg dit foretrukne sprog til universitetets systemer ',
    danish: 'Dansk',
    english: 'Engelsk',
    nextOfKinParagraph: 'Du kan oplyse navn og telefonnummer på en pårørende, så universitetet kan kontakte vedkommende i et nødstilfælde.',
    nextOfKinName: 'Navn',
    nextOfKinTelephone: 'Telefonnummer',
    headerPassword: 'Skift adgangskode',
    save: 'Gem',
    saved: 'Dine ændringer er blevet gemt.',
    mailNotice: '<strong>Vær opmærksom på, at der kan gå op til 15 minutter, før ændringer til din e-mailadresse slår igennem.</strong>',
    cancel: 'Annuller',
    validMail: 'Mailadressen du har skrevet er ikke gyldig.',
    validMailPrivate: 'Mailadressen du har skrevet er ikke gyldig, eller du har forsøgt at benytte din AU-mailadresse som privat mailadresse.',
    validMobile: 'Telefonnummeret du har skrevet er ikke gyldigt.',
    validName: 'Skriv et navn.',
    validFirstName: 'Skriv et fornavn.',
    validLastName: 'Skriv et efternavn.',
    emptyMail: 'Du har endnu ikke tilføjet en privat mailadresse',
    emptyMobile: 'Du har endnu ikke tilføjet et mobilnummer',
    emptyNok: 'Du har endnu ikke tilføjet en pårørende',
    copyAuid: 'Kopiér dit AU-id',
    copyStudentNumber: 'Kopiér dit studienummer',
    headerVacationAbsence: 'Ferie og fravær',
    myProfile: 'Min profil',
    helpModalHeader: 'Nyt: Medarbejderprofil',
    helpModalText: 'Nu kan du rette dine profiloplysninger, skifte adgangskode og se din ferie- og fraværsoversigt på medarbejdere.au.dk. Klik på "Min profil" for at logge ind og se din medarbejderprofil.',
    helpModalBtn: 'Ok, vis ikke igen',
    profileFor: 'Profilmenu for',
    changeLanguageButton: 'Switch to English',
    headerPureProfile: 'Redigér din hjemmeside',
  },
  en: {
    login: 'Sign in',
    logout: 'Sign out',
    edit: 'Edit',
    auId: 'AU ID',
    studentNumber: 'Student registration number',
    changeLanguage: 'Skift sprog',
    headerContainer: 'Profile settings',
    headerName: 'Profile name',
    headerMail: 'Email addresses',
    headerMobile: 'Private mobile phone number',
    headerLanguage: 'System language',
    headerNextOfKin: 'Relatives',
    otherMail: 'See other AU email addresses',
    languageIn: 'You have selected English as the standard language',
    legendName: 'Select your profile name for the university\'s systems',
    legendNameHint: 'Please note that changes will not take effect in all systems.',
    formNameHint: 'Your name from the CPR (civil registration number) register',
    formNameDisplayName: 'Enter another name',
    firstName: 'First name',
    firstNameHint: 'Omit any middle names if you only want one first name to be displayed.',
    lastName: 'Last name',
    workMail: 'AU email address',
    workMailHint: 'Changes to your work mail must reflect your name',
    workMailScreenreader: 'Your work mail is ###MAIL###. You are only able to edit the part before the @.',
    studentMail: 'Student mail address',
    studentMailScreenreader: 'Your student mail address is ###MAIL###. You are only able to edit the part before the @.',
    privateMail: 'Private mail address',
    privateMailHint: 'If you forget your username or password for the university’s systems, we will send an email to your private email address.',
    countryCode: 'Country code',
    number: 'Number',
    languageLegend: 'Select your preferred language for the university’s systems.',
    danish: 'Danish',
    english: 'English',
    nextOfKinParagraph: 'State the name and phone number of a relative so the university can contact the person in question in case of an emergency.',
    nextOfKinName: 'Name',
    nextOfKinTelephone: 'Telephone number',
    headerPassword: 'Change password',
    save: 'Save',
    saved: 'Your changes have been saved',
    mailNotice: '<strong>Please note that it may take up to 15 minutes for changes to your email address to take effect.</strong>',
    cancel: 'Cancel',
    validMail: 'The email address you have entered is not valid.',
    validMailPrivate: 'The email address you have entered is not valid, or you have tried to use your AU email address as your private email address.',
    validMobile: 'The phone number you have entered is not valid.',
    validName: 'Enter a name.',
    validFirstName: 'Enter a first name.',
    validLastName: 'Enter a last name.',
    emptyMail: 'You have not yet added a private email address',
    emptyMobile: 'You have not yet added a mobile number',
    emptyNok: 'You have not yet added information about a relative ',
    copyAuid: 'Copy your AU ID',
    copyStudentNumber: 'Copy your student registration number',
    headerVacationAbsence: 'Holiday and absence',
    myProfile: 'My profile',
    helpModalHeader: 'New features at staff.au.dk',
    helpModalText: 'You can now edit your profile settings, change your password and see your holiday and absence overview at staff.au.dk. Click "My profile" to log in and see your staff profile.',
    helpModalBtn: 'OK, don\'t show again',
    profileFor: 'Profile menu for',
    changeLanguageButton: 'Skift til dansk',
    headerPureProfile: 'Edit your webpage',
  },
};

const passwordLabels = {
  da: {
    currentPassword: 'Skriv din nuværende adgangskode',
    changePassword: 'Skift adgangskode',
    changePasswordInfo: 'Vær opmærksom på, at når du skifter din adgangskode, gælder det alle universitetets systemer.',
    newPassword: 'Skriv en ny adgangskode',
    newPasswordInfo: 'Af sikkerhedsmæssige årsager må du ikke have anvendt din adgangskode tidligere, og den må ikke indeholde personlige oplysninger.',
    repeatPassword: 'Skriv din nye adgangskode igen',
    receipt: '<strong>Godt gået!</strong><br />Din adgangskode er nu skiftet',
    goto: 'Gå til forsiden',
    requirementsHeader: 'Din nye adgangskode skal indholde:',
    requirement1: 'Mindst 12 tegn',
    requirement2: 'Ingen æ, ø, å eller Æ, Ø, Å',
    requirement3: 'Ingen mellemrum',
    requirement4: 'En kombination af bogstaver, tal og disse specialtegn:',
    rules: {
      lowerupper: 'Tilføj et lille bogstav eller et stort bogstav.',
      loweruppernumbers: 'Tilføj en kombination af små bogstaver, store bogstaver og tal.',
      lowerupperspecial: 'Tilføj en kombination af små bogstaver, store bogstaver og specialtegn.',
      loweruppernumbersspecial: 'Din adgangskode skal indeholde en kombination af bogstaver, tal og specialtegn.',
      lowernumbers: 'Tilføj et lille bogstav eller et tal.',
      lowernumbersspecial: 'Tilføj en kombination af små bogstaver, tal og specialtegn.',
      lowerspecial: 'Tilføj et lille bogstav eller et specialtegn.',
      uppernumbers: 'Tilføj et stort bogstav eller et tal.',
      uppernumbersspecial: 'Tilføj en kombination af store bogstaver, tal og specialtegn.',
      upperspecial: 'Tilføj et stort bogstav eller et specialtegn.',
      numbersspecial: 'Tilføj et tal eller et specialtegn.',
      invalid: 'Din adgangskode må ikke indeholde æ, ø, å eller Æ, Ø, Å.',
      length: 'Din adgangskode skal være på mindst ###LENGTH### tegn.',
      spaces: 'Din adgangskode må ikke indeholde mellemrum.',
      specialchars: 'Din adgangskode indholder et specieltegn, som ikke er tilladt.',
    },
    allRequirements: 'Din nye adgangskode opfylder alle krav.',
    passwordsEqual: 'De to adgangskoder er ens',
    passwordsNotEqual: 'Hov, du har skrevet to forskellige adgangskoder. Prøv igen.',
    typeNew: 'Skriv en ny adgangskode',
    repeatNew: 'Skriv din nye adgangskode igen',
    privateMail: 'Privat mailadresse',
    privateMailHint: 'Hvis du ikke har registreret din private mailadresse på Aarhus Universitet, kan du skrive din AU-mailadresse, dit AU-id eller dit studienummer.',
    privateMailHint2: 'Hvis du ikke har registreret din private mailadresse på Aarhus Universitet, kan du skrive din AU-mailadresse.',
    mailReceiptHeader: 'Vi har sendt et link til din mailadresse',
    mailReceiptParagraph: 'Brug linket til at skifte din adgangskode.',
    mailReceiptHeader2: 'Vi har sendt dit AU-brugernavn til din mail',
    mailReceiptHelp: 'Har du ikke modtaget en mail fra os?',
    mailReceiptHelp2: 'Det kan skyldes, at du har indtastet en forkert mailadresse, AU-id eller studienummer.',
    mailReceiptHelp3: 'Det kan skyldes, at du har indtastet en forkert mailadresse.',
    mailReceiptLink: 'Gå tilbage og prøv igen',
    forgotPassword: 'Glemt adgangskode',
    forgotPasswordParagraph: 'Skriv din mailadresse, så sender vi et link, som du kan bruge til at skifte din adgangskode.',
    forgotUsername: 'Glemt brugernavn',
    forgotUsernameParagraph: 'Skriv din mailadresse, så sender vi en mail med dit brugernavn.',
    allGood: 'Perfekt',
    validInput: 'Du har ikke skrevet gyldig mailadresse, AU-id eller studienummer.',
    sendBtnPassword: 'Næste',
    sendBtnUsername: 'Send mit brugernavn',
    expiredHeader: 'Linket er desværre udløbet.',
    expiredText: 'Vær opmærksom på, at linket kun er aktivt i 24 timer.',
    expiredLink: 'Prøv igen',
    goBack: 'Gå tilbage',
    supportHeader: 'Har du brug for hjælp?',
    supportStudentLink: 'https://studerende.au.dk/it-support/',
    supportStudentText: 'Support til studerende',
    supportStaffLink: 'https://medarbejdere.au.dk/administration/it/',
    supportStaffText: 'Support til medarbejdere og andre',
    pwned: 'Adgangskoden kan ikke bruges, da den tidligere har været brugt på andre hjemmesider, som er blevet hacket. Prøv med en anden.',
  },
  en: {
    currentPassword: 'Enter your current password',
    changePassword: 'Change password',
    changePasswordInfo: 'Please note that when you change your password, the change will apply to all the university’s systems.',
    newPassword: 'Enter a new password',
    newPasswordInfo: 'For security reasons, you cannot use a password that you have used before, and the password may not contain personal information.',
    repeatPassword: 'Re-enter your new password',
    receipt: '<strong>Well done!</strong><br />Your password has now been changed',
    goto: 'Go to the front page',
    requirementsHeader: 'Your new password must contain:',
    requirement1: 'At least 12 characters',
    requirement2: 'No \'æ\', \'ø\', \'å\' or \'Æ\', \'Ø\', \'Å\'',
    requirement3: 'No spaces',
    requirement4: 'A combination of letters, numbers and these special characters:',
    rules: {
      lowerupper: 'Add a lower-case letter or an upper-case letter.',
      loweruppernumbers: 'Add a combination of lower-case letters, upper-case letters and numbers.',
      lowerupperspecial: 'Add a combination of lower-case letters, upper-case letters and special characters.',
      loweruppernumbersspecial: 'Your password must contain a combination of letters, numbers and special characters.',
      lowernumbers: 'Add a lower-case letter or a number.',
      lowernumbersspecial: 'Add a combination of lower-case letters, numbers and special characters.',
      lowerspecial: 'Add a lower-case letter or a special character.',
      uppernumbers: 'Add an upper-case letter or a number.',
      uppernumbersspecial: 'Add a combination of upper-case letters, numbers and special characters.',
      upperspecial: 'Add an upper-case letter or a special character.',
      numbersspecial: 'Add a number or a special charactern',
      invalid: 'Your password may not contain the letters \'æ\', \'ø\', \'å\' or \'Æ\', \'Ø\', \'Å\'.',
      length: 'Your password must consist of at least ###LENGTH### characters.',
      spaces: 'Your password may not contain spaces.',
      specialchars: 'Your password contains a special character which is not permitted.',
    },
    allRequirements: 'Your new password meets all requirements.',
    passwordsEqual: 'The passwords match',
    passwordsNotEqual: 'Ups. You have written two different passwords. Please try again.',
    typeNew: 'Enter a new password',
    repeatNew: 'Re-enter your new password',
    privateMail: 'Private email address',
    privateMailHint: 'If you have not registered your private email address at Aarhus University, you can write your AU email address, your AU ID or your student registration number.',
    privateMailHint2: 'If you have not registered your private email address at Aarhus University, you can write your AU email address.',
    mailReceiptHeader: 'We have sent a link to your email address registered',
    mailReceiptParagraph: 'Use the link to change your password.',
    mailReceiptHeader2: 'We have sent an email with your username to your email address',
    mailReceiptHelp: 'Haven’t you received an email from us?',
    mailReceiptHelp2: 'Maybe you have entered the wrong email address, AU ID or student registration number.',
    mailReceiptHelp3: 'Maybe you have entered the wrong email address.',
    mailReceiptLink: 'Please go back and try again',
    forgotPassword: 'Forgot your password?',
    forgotPasswordParagraph: 'Enter your email address. We will then send you a link which you can use to reset your password.',
    forgotUsername: 'Forgot your username?',
    forgotUsernameParagraph: 'Enter your email address. We will then send you an email with your username.',
    allGood: 'Perfect',
    validInput: 'You have not entered a valid email address, AU ID or student registration number.',
    sendBtnPassword: 'Next',
    sendBtnUsername: 'Send my username',
    expiredHeader: 'Unfortunately, the link has expired.',
    expiredText: 'Please note that the link is only active for 24 hours.',
    expiredLink: 'Try again',
    goBack: 'Go back',
    supportHeader: 'Need help?',
    supportStudentLink: 'https://studerende.au.dk/en/it-support/',
    supportStudentText: 'Support for students',
    supportStaffLink: 'https://medarbejdere.au.dk/en/administration/it/',
    supportStaffText: 'Support for staff and others',
    pwned: 'The password cannot be used because it has been used before on other websites that have been hacked. Please try a different password.',
  },
};

const countryCodes = [
  { da: 'Afghanistan', en: 'Afghanistan', code: 93 },
  { da: 'Albanien', en: 'Albania', code: 355 },
  { da: 'Algeriet', en: 'Algeria', code: 213 },
  { da: 'Amerikansk Samoa', en: 'American Samoa', code: 684 },
  { da: 'Andorra', en: 'Andorra', code: 376 },
  { da: 'Angola', en: 'Angola', code: 244 },
  { da: 'Anguilla', en: 'Anguilla', code: 1264 },
  { da: 'Antigua og Barbuda', en: 'Antigua and Barbuda', code: 1268 },
  { da: 'Argentina', en: 'Argentina', code: 54 },
  { da: 'Armenien', en: 'Armenia', code: 374 },
  { da: 'Aruba', en: 'Aruba', code: 297 },
  { da: 'Ascension', en: 'Ascension', code: 247 },
  { da: 'Aserbajdsjan', en: 'Azerbaijan', code: 994 },
  { da: 'Australien', en: 'Australia', code: 61 },
  { da: 'Bahamas', en: 'Bahamas', code: 1242 },
  { da: 'Bahrain', en: 'Bahrain', code: 973 },
  { da: 'Bangladesh', en: 'Bangladesh', code: 880 },
  { da: 'Barbados', en: 'Barbados', code: 1246 },
  { da: 'Belgien', en: 'Belgium', code: 32 },
  { da: 'Belize', en: 'Belize', code: 501 },
  { da: 'Benin', en: 'Benin', code: 229 },
  { da: 'Bermuda', en: 'Bermuda', code: 1441 },
  { da: 'Bhutan', en: 'Bhutan', code: 975 },
  { da: 'Bolivia', en: 'Bolivia', code: 591 },
  { da: 'Bosnien-Hercegovina', en: 'Bosnia Herzegovina', code: 387 },
  { da: 'Botswana', en: 'Botswana', code: 267 },
  { da: 'Brasilien', en: 'Brazil', code: 55 },
  { da: 'Britiske Jomfruøer', en: 'British Virgin Islands', code: 1284 },
  { da: 'Brunei', en: 'Brunei', code: 673 },
  { da: 'Bulgarien', en: 'Bulgaria', code: 359 },
  { da: 'Burkina Faso', en: 'Burkina Faso', code: 226 },
  { da: 'Burma', en: 'Burma', code: 95 },
  { da: 'Burundi', en: 'Burundi', code: 257 },
  { da: 'Cambodja', en: 'Cambodia', code: 855 },
  { da: 'Cameroun', en: 'Cameroon', code: 237 },
  { da: 'Canada', en: 'Canada', code: 1 },
  { da: 'Caymanøerne', en: 'Cayman Islands', code: 1345 },
  { da: 'Centralafrikanske Republik', en: 'Centralafrican Republic', code: 236 },
  { da: 'Chile', en: 'Chile', code: 56 },
  { da: 'Cocosøerne', en: 'Cocos', code: 619162 },
  { da: 'Colombia', en: 'Colombia', code: 57 },
  { da: 'Comorerne', en: 'Comoros', code: 269 },
  { da: 'Cook-øerne', en: 'Cook Islands', code: 682 },
  { da: 'Costa Rica', en: 'Costa Rica', code: 506 },
  { da: 'Cuba', en: 'Cuba', code: 53 },
  { da: 'Cypern', en: 'Cyprus', code: 357 },
  {
    da: 'Danmark',
    en: 'Denmark',
    code: 45,
    important: true,
  },
  { da: 'Demokratiske Republik Congo', en: 'Democratic Republic of Congo', code: 243 },
  { da: 'Diego Garcia', en: 'Diego Garcia', code: 246 },
  { da: 'Djibouti', en: 'Djibouti', code: 253 },
  { da: 'Dominica', en: 'Dominica', code: 1767 },
  { da: 'Dominikanske Republik', en: 'Dominican Republic', code: 1809 },
  { da: 'Ecuador', en: 'Ecuador', code: 593 },
  { da: 'Egypten', en: 'Egypt', code: 20 },
  { da: 'El Salvador', en: 'El Salvador', code: 503 },
  { da: 'Elfenbenskysten', en: 'Ivory Coast', code: 225 },
  { da: 'Eritrea', en: 'Eritrea', code: 291 },
  { da: 'Estland', en: 'Estonia', code: 372 },
  { da: 'Etiopien', en: 'Ethiopia', code: 251 },
  { da: 'Falklandsøerne', en: 'Falklands', code: 500 },
  { da: 'Fiji', en: 'Fiji', code: 679 },
  { da: 'Filippinerne', en: 'Phillipines', code: 63 },
  { da: 'Finland', en: 'Finland', code: 358 },
  { da: 'Forenede Arabiske Emirater', en: 'United Arab Emirates', code: 971 },
  { da: 'Frankrig', en: 'France', code: 33 },
  { da: 'Fransk Guyana', en: 'French Guiana', code: 594 },
  { da: 'Fransk Polynesien', en: 'French Polynesia', code: 689 },
  { da: 'Færøerne', en: 'Faroe Islands', code: 298 },
  { da: 'Gabon', en: 'Gabon', code: 241 },
  { da: 'Gambia', en: 'Gambia', code: 220 },
  { da: 'Georgien', en: 'Georgia', code: 995 },
  { da: 'Ghana', en: 'Ghana', code: 233 },
  { da: 'Gibraltar', en: 'Gibraltar', code: 350 },
  { da: 'Grenada', en: 'Grenada', code: 1473 },
  { da: 'Grækenland', en: 'Greece', code: 30 },
  { da: 'Grønland', en: 'Greenland', code: 299 },
  { da: 'Guadeloupe', en: 'Guadeloupe', code: 590 },
  { da: 'Guam', en: 'Guam', code: 1671 },
  { da: 'Guatemala', en: 'Guatemala', code: 502 },
  { da: 'Guinea', en: 'Guinea', code: 224 },
  { da: 'Guinea-Bissau', en: 'Guinea-Bissau', code: 245 },
  { da: 'Guyana', en: 'Guyana', code: 592 },
  { da: 'Haiti', en: 'Haiti', code: 509 },
  { da: 'Honduras', en: 'Honduras', code: 504 },
  { da: 'Hong Kong', en: 'Hong Kong', code: 852 },
  { da: 'Hviderusland', en: 'Belarus', code: 375 },
  { da: 'Indien', en: 'India', code: 91 },
  { da: 'Indonesien', en: 'Indonesia', code: 62 },
  { da: 'Irak', en: 'Iraq', code: 964 },
  { da: 'Iran', en: 'Iran', code: 98 },
  { da: 'Irland', en: 'Ireland', code: 353 },
  { da: 'Island', en: 'Iceland', code: 354 },
  { da: 'Israel', en: 'Israel', code: 972 },
  { da: 'Italien', en: 'Italy', code: 39 },
  { da: 'Jamaica', en: 'Jamaica', code: 1876 },
  { da: 'Japan', en: 'Japan', code: 81 },
  { da: 'Jomfruøerne', en: 'Virgin Islands', code: 1340 },
  { da: 'Jordan', en: 'Jordan', code: 962 },
  { da: 'Juleøen', en: 'Christmas Island', code: 619164 },
  { da: 'Kap Verde', en: 'Cape Verde', code: 238 },
  { da: 'Kasakhstan', en: 'Kazakhstan', code: 7 },
  { da: 'Kenya', en: 'Kenya', code: 254 },
  { da: 'Kina', en: 'China', code: 86 },
  { da: 'Kirgisistan', en: 'Kyrgyzstan', code: 996 },
  { da: 'Kiribati', en: 'Kiribati', code: 686 },
  { da: 'Kroatien', en: 'Croatia', code: 385 },
  { da: 'Kuwait', en: 'Kuwait', code: 965 },
  { da: 'Laos', en: 'Laos', code: 856 },
  { da: 'Lesotho', en: 'Lesotho', code: 266 },
  { da: 'Letland', en: 'Latvia', code: 371 },
  { da: 'Libanon', en: 'Lebanon', code: 961 },
  { da: 'Liberia', en: 'Liberia', code: 231 },
  { da: 'Libyen', en: 'Libya', code: 218 },
  { da: 'Liechtenstein', en: 'Liechtenstein', code: 423 },
  { da: 'Litauen', en: 'Lithuania', code: 370 },
  { da: 'Luxemburg', en: 'Luxemburg', code: 352 },
  { da: 'Macau', en: 'Macau', code: 853 },
  { da: 'Madagaskar', en: 'Madagascar', code: 261 },
  { da: 'Makedonien', en: 'Macedonia', code: 389 },
  { da: 'Malawi', en: 'Malawi', code: 265 },
  { da: 'Malaysia', en: 'Malaysia', code: 60 },
  { da: 'Maldiverne', en: 'Maldives', code: 960 },
  { da: 'Mali', en: 'Mali', code: 223 },
  { da: 'Malta', en: 'Malta', code: 356 },
  { da: 'Marokko', en: 'Morocco', code: 212 },
  { da: 'Marshalløerne', en: 'Marshall Islands', code: 692 },
  { da: 'Martinique', en: 'Martinique', code: 596 },
  { da: 'Mauretanien', en: 'Mauritania', code: 222 },
  { da: 'Mauritius', en: 'Mauritius', code: 230 },
  { da: 'Mayotte', en: 'Mayotte', code: 262 },
  { da: 'Mexico', en: 'Mexico', code: 52 },
  { da: 'Mikronesien', en: 'Micronesia', code: 691 },
  { da: 'Moldova', en: 'Moldova', code: 373 },
  { da: 'Monaco', en: 'Monaco', code: 377 },
  { da: 'Mongoliet', en: 'Mongolia', code: 976 },
  { da: 'Montenegro', en: 'Montenegro', code: 382 },
  { da: 'Montserrat', en: 'Montserrat', code: 1664 },
  { da: 'Mozambique', en: 'Mozambique', code: 258 },
  { da: 'Namibia', en: 'Namibia', code: 264 },
  { da: 'Nauru', en: 'Nauru', code: 674 },
  { da: 'Nederlandene', en: 'Netherlands', code: 31 },
  { da: 'Nederlandske Antiller', en: 'Netherlands Antilles', code: 599 },
  { da: 'Nepal', en: 'Nepal', code: 977 },
  { da: 'New Zealand', en: 'New Zealand', code: 64 },
  { da: 'Nicaragua', en: 'Nicaragua', code: 505 },
  { da: 'Niger', en: 'Niger', code: 227 },
  { da: 'Nigeria', en: 'Nigeria', code: 234 },
  { da: 'Niue', en: 'Niue', code: 683 },
  { da: 'Nordirland', en: 'Northern Ireland', code: 44 },
  { da: 'Nordkorea', en: 'North Korea', code: 850 },
  { da: 'Norfolk', en: 'Norfolk', code: 672 },
  { da: 'Norge', en: 'Norway', code: 47 },
  { da: 'Ny Caledonien', en: 'New Caledonia', code: 687 },
  { da: 'Oman', en: 'Oman', code: 968 },
  { da: 'Pakistan', en: 'Pakistan', code: 92 },
  { da: 'Panama', en: 'Panama', code: 507 },
  { da: 'Papua Ny Guinea', en: 'Papua New Guinea', code: 675 },
  { da: 'Paraguay', en: 'Paraguay', code: 595 },
  { da: 'Peru', en: 'Peru', code: 51 },
  { da: 'Polen', en: 'Poland', code: 48 },
  { da: 'Portugal', en: 'Portugal', code: 351 },
  { da: 'Puerto Rico', en: 'Puerto Rico', code: 1787 },
  { da: 'Qatar', en: 'Qatar', code: 974 },
  { da: 'Republikken Congo', en: 'Republic of the Congo', code: 242 },
  { da: 'Réunion', en: 'Reunion', code: 262 },
  { da: 'Rumænien', en: 'Romania', code: 40 },
  { da: 'Rusland', en: 'Russia', code: 7 },
  { da: 'Rwanda', en: 'Rwanda', code: 250 },
  { da: 'Saint Helena', en: 'Saint Helena', code: 290 },
  { da: 'Saint Kitts og Nevis', en: 'Saint Kitts and Nevis', code: 1869 },
  { da: 'Saint Lucia', en: 'Saint Lucia', code: 1758 },
  { da: 'Saint Pierre og Miquelon', en: 'Saint Pierre and Miquelon', code: 508 },
  { da: 'Saint Vincent og Grenadinerne', en: 'Saint Vincent and the Grenadines', code: 1784 },
  { da: 'Salomonøerne', en: 'Solomons', code: 677 },
  { da: 'San Marino', en: 'San Marino', code: 378 },
  { da: 'Sao Tomé og Principe', en: 'Sao Tome and Principe', code: 239 },
  { da: 'Saudi-Arabien', en: 'Saudi Arabia', code: 966 },
  { da: 'Schweiz', en: 'Switzerland', code: 41 },
  { da: 'Senegal', en: 'Senegal', code: 221 },
  { da: 'Serbien', en: 'Serbia', code: 381 },
  { da: 'Seychellerne', en: 'Seychelles', code: 248 },
  { da: 'Sierra Leone', en: 'Sierra Leone', code: 232 },
  { da: 'Singapore', en: 'Singapore', code: 65 },
  { da: 'Slovakiet', en: 'Slovakia', code: 421 },
  { da: 'Slovenien', en: 'Slovenia', code: 386 },
  { da: 'Somalia', en: 'Somalia', code: 252 },
  { da: 'Spanien', en: 'Spain', code: 34 },
  { da: 'Sri Lanka', en: 'Sri Lanka', code: 94 },
  { da: 'Storbritannien', en: 'United Kingdom', code: 44 },
  { da: 'Sudan', en: 'Sudan', code: 249 },
  { da: 'Surinam', en: 'Suriname', code: 597 },
  { da: 'Sverige', en: 'Sweden', code: 46 },
  { da: 'Swaziland', en: 'Swaziland', code: 268 },
  { da: 'Sydafrika', en: 'South Africa', code: 27 },
  { da: 'Sydkorea', en: 'South Korea', code: 82 },
  { da: 'Sydsudan', en: 'South Sudan', code: 211 },
  { da: 'Syrien', en: 'Syria', code: 963 },
  { da: 'Tadsjikistan', en: 'Tajikistan', code: 992 },
  { da: 'Taiwan', en: 'Taiwan', code: 886 },
  { da: 'Tanzania', en: 'Tanzania', code: 255 },
  { da: 'Tchad', en: 'Chad', code: 235 },
  { da: 'Thailand', en: 'Thailand', code: 66 },
  { da: 'Tjekkiet', en: 'Czech republic', code: 420 },
  { da: 'Togo', en: 'Togo', code: 228 },
  { da: 'Tonga', en: 'Tonga', code: 676 },
  { da: 'Trinidad og Tobago', en: 'Trinidad and Tobago', code: 1868 },
  { da: 'Tunesien', en: 'Tunisia', code: 216 },
  { da: 'Turkmenistan', en: 'Turkmenistan', code: 993 },
  { da: 'Turks- og Caicosøerne', en: 'Turks and Caicos Islands', code: 1649 },
  { da: 'Tuvalu', en: 'Tuvalu', code: 688 },
  { da: 'Tyrkiet', en: 'Turkey', code: 90 },
  { da: 'Tyskland', en: 'Germany', code: 49 },
  { da: 'Uganda', en: 'Uganda', code: 256 },
  { da: 'Ukraine', en: 'Ukraine', code: 380 },
  { da: 'Ungarn', en: 'Hungary', code: 36 },
  { da: 'Uruguay', en: 'Uruguay', code: 598 },
  { da: 'USA', en: 'USA', code: 1 },
  { da: 'Usbekistan', en: 'Uzbekistan', code: 998 },
  { da: 'Vanuatu', en: 'Vanuatu', code: 678 },
  { da: 'Venezuela', en: 'Venezuela', code: 58 },
  { da: 'Vest Samoa', en: 'West Samoa', code: 685 },
  { da: 'Vietnam', en: 'Vietnam', code: 84 },
  { da: 'Yemen', en: 'Yemen', code: 967 },
  { da: 'Zambia', en: 'Zambia', code: 260 },
  { da: 'Zimbabwe', en: 'Zimbabwe', code: 263 },
  { da: 'Ækvatorialguinea', en: 'Equatorial Guinea', code: 240 },
  { da: 'Østrig', en: 'Austria', code: 43 },
];


export {
  flowboxLabels,
  newsLabels,
  universeLabels,
  profileLabels,
  passwordLabels,
  countryCodes,
};
