/* eslint-env browser */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AUSpinnerComponent from '../delphinus/AUSpinnerComponent';

const AUUserContextComponent = ({
  auid,
  contextKey,
  spinner,
  children,
  handleContext,
  handleError,
  loginComponent,
  emptyComponent,
  clearCache,
  loggedIn,
  enrichContext,
}) => {
  const { auAuth } = window;
  const [hasContext, setHasContext] = useState(false);
  const setContext = () => {
    if ((loggedIn || auAuth.getUser() != null) && !hasContext) {
      auAuth.setUserContext(auid, contextKey, clearCache, (userContext) => {
        setHasContext(true);
        handleContext(userContext);
        console.log(`Context for key ${contextKey} has been received and sent to handler`);
      }, (status, json) => {
        setHasContext(true); // Her skal vi måske have en special fejltilstand?
        handleError(status, json);
      }, true, loggedIn, enrichContext);
    }
  };
  useEffect(() => {
    if (!spinner) {
      setContext();
    }
  });

  // Hvis vi vil vise en loginskærm, fx på mitstudie
  if (loginComponent != null && auAuth.getUser() == null && !loggedIn) {
    return loginComponent;
  }

  // Hvis spinner er slået til, viser vi content deri
  if (spinner) {
    return (
      <AUSpinnerComponent
        onLoad={setContext}
        loadingCondition={!hasContext}
        loaded={hasContext}
      >
        {children}
      </AUSpinnerComponent>
    );
  }

  // Hvis vi ikke er indlæst har vi mulighed for at vise en tom tilstand
  if (!hasContext) {
    return emptyComponent;
  }

  return children;
};

AUUserContextComponent.defaultProps = {
  auid: 0,
  spinner: true,
  loginComponent: null,
  emptyComponent: null,
  clearCache: false,
  loggedIn: false,
  handleError: (status, json) => console.error(status, json),
  enrichContext: async (json) => json,
};

AUUserContextComponent.propTypes = {
  auid: PropTypes.number,
  contextKey: PropTypes.string,
  spinner: PropTypes.bool,
  children: PropTypes.element.isRequired,
  handleContext: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  loginComponent: PropTypes.element,
  emptyComponent: PropTypes.element,
  clearCache: PropTypes.bool,
  loggedIn: PropTypes.bool,
  enrichContext: PropTypes.func.isRequired,
};

AUUserContextComponent.displayName = 'AUUserContextComponent';
export default AUUserContextComponent;
