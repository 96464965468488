/* eslint-env browser */
import React from 'react';
import PropTypes from 'prop-types';

const parseBurgerLinks = (elements) => {
  const links = [];
  elements.forEach((e) => {
    const link = e.querySelector('a');
    links.push({
      url: link.getAttribute('href'),
      title: link.innerText,
      className: e.getAttribute('class'),
    });
  });
  return links;
};

class StudentTopComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: 0,
      burgerLinks: [],
    };
  }

  componentDidMount() {
    const { promiseHandler } = this.props;
    document.getElementById('fade').addEventListener('click', () => {
      this.setState({
        active: 0,
      });
    });

    promiseHandler((data) => {
      const container = document.createElement('div');
      container.innerHTML = data;
      const burgerLinks = container.querySelectorAll('ul.burger-links > li');
      this.setState({
        burgerLinks: parseBurgerLinks(burgerLinks),
      });
    });
  }

  componentDidUpdate() {
    const { active } = this.state;
    const fade = document.getElementById('fade');
    if (active > 0) {
      fade.style.display = 'block';
    } else {
      fade.style.display = 'none';
    }
    fade.style.opacity = 0;
    if (active === 1 && typeof window.findWidgetInit !== 'undefined') {
      window.findWidgetInit('.universe-utility .find-container');
    }
    if (active === 2) {
      AU.alphabox.init();
    }
  }

  render() {
    const { lang, universeLabels } = this.props;
    const { active, burgerLinks } = this.state;

    const renderBurgerLinks = burgerLinks.map(
      (u) => (
        <li key={u.title} className={u.className}>
          <a href={u.url}>
            {u.title}
          </a>
        </li>
      ),
    );

    return [
      <div key="header" className="large-12 medium-12 small-12 columns">
        <div>
          <h2 className="universe-home" dangerouslySetInnerHTML={{ __html: universeLabels[lang].studentTitleLink }} />
          <ul className="resetlist universe-icons">
            <li className="mitstudie">
              <a href={universeLabels[lang].mitstudieUrl}>{universeLabels[lang].mitstudieText}</a>
            </li>
            <li className="au" dangerouslySetInnerHTML={{ __html: universeLabels[lang].mainPageLink }} />
            <li className={active === 1 ? 'active' : null}>
              <button
                type="button"
                className="btn-find"
                onClick={() => {
                  this.setState((prevState) => ({
                    active: prevState.active === 1 ? 0 : 1,
                  }));
                }}
              >
                Find
              </button>
            </li>
            <li className={active === 2 ? 'active' : null}>
              <button
                type="button"
                className="btn-menu"
                onClick={() => {
                  this.setState((prevState) => ({
                    active: prevState.active === 2 ? 0 : 2,
                  }));
                }}
              >
                Menu
              </button>
            </li>
          </ul>
        </div>
      </div>,
      <div key={1} className={`universe-utility${active === 1 ? ' active' : ''}`}>
        <div className="find-container au-map" />
      </div>,
      <div key={2} className={`universe-utility${active === 2 ? ' active' : ''}`}>
        <div className="au_alphabox" id="au_alphabox_student_utility" />
        <div className="universe-utility-links">
          <ul className="resetlist">
            {renderBurgerLinks}
          </ul>
        </div>
      </div>,
    ];
  }
}

StudentTopComponent.propTypes = {
  lang: PropTypes.string.isRequired,
  promiseHandler: PropTypes.func.isRequired,
  universeLabels: PropTypes.shape({}).isRequired,
};

StudentTopComponent.displayName = 'StudentTopComponent';
export default StudentTopComponent;
