/* eslint-env browser */
import React from 'react';
import PropTypes from 'prop-types';
import { isElementInViewport } from '../../lib/helpers';

class AUSpinnerComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      visible: props.visible,
    };

    this.lazyLoad = this.lazyLoad.bind(this);
  }

  componentDidMount() {
    this.lazyLoad();
    window.addEventListener('scroll', () => {
      this.lazyLoad();
    });
  }

  componentDidUpdate() {
    this.lazyLoad();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.visible !== prevState.visible) {
      return {
        visible: nextProps.visible,
      };
    }

    return null;
  }

  lazyLoad() {
    const {
      loadingCondition,
      loaded,
      domID,
      onLoad,
    } = this.props;
    const { loading, visible } = this.state;

    const element = document.getElementById(domID);
    if (!loaded && loading && loadingCondition && (visible || isElementInViewport(element))) {
      this.setState({
        loading: false,
      }, () => {
        onLoad();
      });
    }
  }

  render() {
    const {
      className, content, loaded, domID, children, height, init,
    } = this.props;

    if (!loaded) {
      if (init === 'table') {
        return (
          <div id={domID} className={`processing-state processing-state--init-table ${className}`} style={{ minHeight: height }} />
        );
      }
      if (init === 'box') {
        return (
          <div id={domID} className={`processing-state processing-state--init-box ${className}`} style={{ minHeight: height }} />
        );
      }
      return (
        <div id={domID} className={`processing-state ${className}`} style={{ minHeight: height }} />
      );
    }

    return children || content;
  }
}

AUSpinnerComponent.defaultProps = {
  domID: 'au-spinner-component',
  visible: false,
  className: '',
  content: null,
  children: null,
  onLoad: () => { },
  height: 'auto',
  init: '',
};

AUSpinnerComponent.propTypes = {
  /**
   * Relevant ved mere end en spinner per side
   */
  domID: PropTypes.string,
  /**
   * Om den er synlig på skærmen ved indlæsning
   */
  visible: PropTypes.bool,
  className: PropTypes.string,
  content: PropTypes.element,
  children: PropTypes.element,
  loaded: PropTypes.bool.isRequired,
  loadingCondition: PropTypes.bool.isRequired,
  onLoad: PropTypes.func,
  height: PropTypes.string,
  init: PropTypes.string,
};

AUSpinnerComponent.displayName = 'AUSpinnerComponent';
export default AUSpinnerComponent;
