/* eslint-env browser */
import React from 'react';
import PropTypes from 'prop-types';
import AUProfileWidgetComponent from './AUProfileWidgetComponent';
import AUUserContextComponent from '../context/AUUserContextComponent';

const AUProfileAvatar2016Component = ({
  lang,
  user,
  settings,
  toggled,
  onToggle,
  onLoad,
  profileLabels,
  logoutUri,
}) => {
  const {
    chosenFirstNames,
    chosenLastName,
    auId,
    studentNumber,
  } = user;

  const content = (
    <div className={`sub-nav${toggled ? ' sub-nav--toggled' : ''}`}>
      <button
        id="profile-menu-toggle"
        type="button"
        aria-label={toggled ? `${profileLabels[lang].profileFor} ${chosenFirstNames} ${chosenLastName}` : profileLabels[lang].myProfile}
        aria-expanded={toggled}
        onClick={() => {
          onToggle();
        }}
      >
        {chosenFirstNames || profileLabels[lang].myProfile}
      </button>
      {
        toggled && (
          <AUProfileWidgetComponent
            lang={lang}
            name={`${chosenFirstNames} ${chosenLastName}`}
            auid={auId}
            studentNumber={studentNumber}
            settings={settings}
            profileLabels={profileLabels}
            logoutUri={logoutUri}
          />
        )
      }
    </div>
  );

  if (auId === 0) {
    return null;
  }

  return (
    <AUUserContextComponent
      auid={auId}
      loggedIn
      contextKey="staff-user"
      handleContext={(userContext) => {
        onLoad(userContext);
      }}
      handleError={(status, json) => {
        console.log(status, json);
      }}
      spinner={false}
      emptyComponent={content}
      clearCache={false}
    >
      {content}
    </AUUserContextComponent>
  );
};

AUProfileAvatar2016Component.propTypes = {
  lang: PropTypes.string.isRequired,
  user: PropTypes.shape({
    chosenFirstNames: PropTypes.string.isRequired,
    chosenLastName: PropTypes.string.isRequired,
    preferredLanguage: PropTypes.string.isRequired,
    auId: PropTypes.number.isRequired,
    studentNumber: PropTypes.string.isRequired,
  }).isRequired,
  auAuth: PropTypes.shape({}).isRequired,
  settings: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
  toggled: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onLoad: PropTypes.func.isRequired,
  profileLabels: PropTypes.shape({}).isRequired,
  logoutUri: PropTypes.string.isRequired,
};

AUProfileAvatar2016Component.displayName = 'AUProfileAvatar2016Component';
export default AUProfileAvatar2016Component;
